@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('./fonts/PlusJakartaDisplay-Light.woff2') format('woff2'),
    url('./fonts/PlusJakartaDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('./fonts/PlusJakartaDisplay-LightItalic.woff2') format('woff2'),
    url('./fonts/PlusJakartaDisplay-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('./fonts/PlusJakartaDisplay-Regular.woff2') format('woff2'),
    url('./fonts/PlusJakartaDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('./fonts/PlusJakartaDisplay-Italic.woff2') format('woff2'),
    url('./fonts/PlusJakartaDisplay-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Display';
  src: url('./fonts/PlusJakartaDisplay-BoldItalic.woff2') format('woff2'),
    url('./fonts/PlusJakartaDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/NunitoSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}
/* .MuiContainer-root {
  display: none !important;
} */

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  overflow-x: auto;
}
@media (max-width: 768px) {
  body {
    padding: 0 !important;
    overflow: auto !important;
    height: auto !important;
  }
}
#root {
  flex-grow: 1;
}
input::placeholder {
  color: #cccdd1;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccdd1;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccdd1;
}
svg > g > g:last-child {
  pointer-events: none;
}
/* input:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
} */
a {
  text-decoration: none;
}
button.disabled:disabled {
  opacity: 0.8;
}
.logo-sidebar {
  transition: width 0.2s;
}
.logo-sidebar g {
  transition: opacity 0.2s;
}
#recaptch-v2-container {
  margin-top: 20px;
}
#recaptch-v2-container > div {
  margin: auto;
}
#recaptch-v2-container:empty {
  display: none;
}
.grecaptcha-badge {
  visibility: hidden;
}
.sigin-image {
  position: relative;
}
.sigin-image:before {
  content: '';
  display: block;
  width: 100%;
  height: 130px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,000000+100&0+0,0.8+100 */
  background: -moz-linear-gradient(
    top,
    rgba(125, 185, 232, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(125, 185, 232, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(125, 185, 232, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
}
#recaptcha-text {
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 1024px) {
  #recaptcha-text {
    position: static;
    text-align: center;
    margin: 10px 15px;
  }
}
.PhoneInput {
  position: relative;
  width: 100%;
}
.PhoneInputCountry {
  position: absolute !important;
  left: 10px;
  top: 18px;
  z-index: 2;
}
.PhoneInput input {
  padding-left: 48px;
}
.PhoneInputCountrySelect {
  color: #fff;
}
.custom-select-button {
  padding-inline-start: 2px !important;
  padding-inline-end: 2px !important;
}
.custom-select-button span:first-child {
  /* width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: 44px;
  border-radius: 12px;
  border: transparent;
  border-color: inherit;
  background: rgb(19,21,54);
  color: white;
  max-width: 100%;
  display: flex;
  align-items: center; */
  padding-left: 6px;
}
.custom-select-button span:last-child {
  position: absolute;
  top: 8px;
  right: 15px;
}
.phone-custom-select-button span:last-child {
  padding: 0;
  top: 12px;
}
.chakra-numberinput input {
  border-radius: 12px;
  height: 46px;
}
.option-hide-checkbox .chakra-menu__icon-wrapper {
  display: none;
}
/* input[disabled],
input[aria-disabled='true'],
input[data-disabled] {
  opacity: 1 !important;
} */
.gradient-border[disabled] {
  opacity: 0.5;
}
.chakra-ui-light .active-menu-item > .chakra-button {
  background-color: var(--chakra-colors-lightBlueColor);
  backdrop-filter: blur(42px);
}
.active-menu-item > .chakra-button {
  background-color: var(--chakra-colors-gray-700);
  backdrop-filter: blur(42px);
}
.active-menu-item > .chakra-button .sidebar-menu-icon {
  background-color: #008cff;
}
.active-menu-item > .chakra-button .sidebar-menu-icon svg path {
  fill: #fff;
}
.menu-partial-visible .active-menu-item > .chakra-button {
  background-color: transparent;
}
.active-menu-item > .chakra-button:after {
  content: '';
  display: block;
  width: 12px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #008cff;
}
.submenu {
  height: 0;
  transition: height 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}
.submenu.submenu-open {
  height: 220px;
  opacity: 1;
  visibility: visible;
}
.submenu-item svg path {
  fill: var(--chakra-colors-lightGrayColor);
}
.submenu-item:hover svg path {
  fill: #fff;
}
.chakra-ui-dark .active-menu-item .submenu-item {
  background-color: var(--chakra-colors-lightGrayColor) !important;
  color: #fff;
}
.chakra-ui-light .active-menu-item .submenu-item {
  background-color: var(--chakra-colors-gray-500) !important;
  color: #fff;
}
.active-menu-item .submenu-item svg path {
  fill: #fff;
}
.menu-partial-visible .active-menu-item > .chakra-button:after {
  width: 3px;
}
.pagination {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  justify-content: flex-end;
}
.pagination a {
  font-family: 'Nunito Sans';
  display: block;
  padding: 4px 5px;
  background: var(--chakra-colors-gray-700);
  border-radius: 6px;
  margin: 0px 2px;
  min-width: 30px;
  height: 30px;
  text-align: center;

  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chakra-ui-dark .pagination a {
  color: #fff;
}
.chakra-ui-light .pagination a {
  color: var(--chakra-colors-lightGrayColor);
  background: var(--chakra-colors-lightBlueColor);
}
.chakra-ui-dark .pagination a {
  color: #fff;
}
.pagination .page-link.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.chakra-ui-dark .pagination .page-item.active a {
  background: #008cff;
  color: #fff;
}
.chakra-ui-light .pagination .page-item.active a {
  background: #008cff;
  color: #fff;
}
.slick-slider {
  height: 100%;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  height: 100%;
}
.slick-slide > div {
  height: 100%;
}
.capitalize:first-letter {
  text-transform: capitalize;
}
.chakra-ui-dark input[type='date'] {
  color-scheme: dark;
}
.details-row {
  max-height: 0;
  opacity: 0;
  transition: opacity 0.6s, max-height 0.4s, border-width 0.2s;
}
.details-row.active {
  opacity: 1;
  max-height: 700px;
}
.fade-ready {
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: gray;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
}
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1a1f37;
}
.company-heading::after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background-color: #008cff;
  margin-top: -3px;
}
@media (max-width: 768px) {
  .company-heading::after {
    display: none;
  }
}
table {
  border-radius: 16px;
  overflow: hidden;
}
.flicker-animation {
  animation-name: blink;
  animation-duration: 1.33s;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
}
.flicker-animation-color {
  animation-name: blink-color;
  animation-duration: 1.33s;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
}
@media (min-width: 768px) {
  .providers-2 > div:last-child {
    border-left: 4px solid var(--chakra-colors-gray-700);
  }
  .providers-3 > div:nth-child(2) {
    border-left: 4px solid var(--chakra-colors-gray-700);
    border-right: 4px solid var(--chakra-colors-gray-700);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
    filter: contrast(1);
  }
  20% {
    opacity: 0.5;
    filter: contrast(0);
  }
  100% {
    opacity: 1;
    filter: contrast(1);
  }
}

@keyframes blink-color {
  0% {
    color: #cccdd1;
  }
  20% {
    color: #008cff;
  }
  100% {
    color: #cccdd1;
  }
}
.shepherd-element {
  border-radius: 10px;
}
.shepherd-content {
  border-radius: 8px;
}
.chakra-ui-dark .shepherd-element {
  border: 3px solid #f2d15c;
  background: #33383e;
}
.chakra-ui-light .shepherd-element {
  border: 3px solid #f2d15c;
  background: #f5f5f5;
}
.chakra-ui-dark .shepherd-has-title .shepherd-content {
  background: #33383e;
}
.chakra-ui-light .shepherd-has-title .shepherd-content {
  background: #f5f5f5;
}
.shepherd-has-title .shepherd-content .shepherd-header {
  background: transparent !important;
}
.shepherd-has-title .shepherd-content .shepherd-header {
  display: block;
  padding: 10px;
  font-size: 15px;
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 25px;
}
.chakra-ui-dark .shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  background-color: #393d43;
  color: #f2d15c;
}
.chakra-ui-dark
  .shepherd-has-title
  .shepherd-content
  .shepherd-cancel-icon:hover {
  color: rgb(194 194 194 / 75%);
}
.chakra-ui-light .shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  background-color: #fff;
  color: #f2d15c;
}
.chakra-ui-dark .shepherd-title,
.chakra-ui-light .shepherd-title {
  color: #f2d15c;
  font-weight: bold;
}
.chakra-ui-dark .shepherd-text {
  color: #cccdd1;
}
.chakra-ui-light .shepherd-text {
  color: #242529;
}
.shepherd-button-primary.shepherd-button {
  border: 1px solid #f2d15c;
  background: transparent;
  padding: 5px 15px;
}
.chakra-ui-dark .shepherd-button-primary.shepherd-button {
  color: hsla(0, 0%, 100%, 0.75);
}
.chakra-ui-light .shepherd-button-primary.shepherd-button {
  color: #242529;
}
.chakra-ui-dark .shepherd-button-primary.shepherd-button:not(:disabled):hover {
  background: #f2d15c;
  color: #fff;
}
.chakra-ui-light .shepherd-button-primary.shepherd-button:not(:disabled):hover {
  background: #f2d15c;
  color: #fff;
}
.shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
  > .shepherd-arrow:before,
.shepherd-arrow:before {
  background-color: #f2d15c !important;
}

.highlight.shepherd-enabled.shepherd-target:after {
  content: '';
}
.create-button.shepherd-enabled button {
  margin: 5px;
}
.tour-form-element.shepherd-enabled > div {
  margin: 5px;
}
.shepherd-enabled.shepherd-target {
  border: 1px solid #f3d15c;
}
.do-not-show-button {
  margin-right: auto !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  padding: 0px !important;
  font-size: 14px;
}
.chakra-ui-dark .do-not-show-button {
  color: #cccdd1 !important;
}
.do-not-show-button:before {
  content: '';
  display: block;
  width: var(--chakra-sizes-5);
  height: var(--chakra-sizes-5);
  border-radius: var(--chakra-radii-sm);
  margin-right: 8px;
}
.chakra-ui-dark .do-not-show-button:before {
  border: 1px solid var(--chakra-colors-whiteAlpha-300);
}
.chakra-ui-light .do-not-show-button:before {
  border: 1px solid var(--chakra-colors-lightGrayColor);
}
.file-upload-button {
  width: 100px;
  height: 100%;
  border-radius: 16px;
  color: #fff;
  border-width: 2px;
  border-color: var(--chakra-colors-brand-200);
}
.step-error {
  border-color: var(--chakra-colors-red-400) !important;
}
.step-error[data-status='complete'] {
  border-width: 2px;
  background: transparent !important;
  color: #fff !important;
  border-color: var(--chakra-colors-red-400) !important;
}
.dots-loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}
@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
